@charset 'UTF-8'

// https://type-scale.com/
$font-ratio = 1.2

:root
  --transition 0.3s
  @media screen and (prefers-reduced-motion: reduce)
    --transition 0.001s

  --primary-hue 90deg
  --secondary-hue 30deg

  --background #eee
  --foreground #222
  --faint-background #e8e8e8
  --faint-foreground #888
  --console-background #2f2f2f
  --console-foreground #eee
  --tag-background #db7
  --primary-color s('hsl(var(--primary-hue), 30%, 30%)')
  --secondary-color s('hsl(var(--secondary-hue), 30%, 30%)')
  --link-color #39c
  --word-spacing 0
  --image-opacity 1
  &.dark-theme
    --background #222
    --foreground #ccc
    --faint-background #333
    --faint-foreground #888
    --console-foreground #ccc
    --tag-background #753
    --primary-color s('hsl(var(--primary-hue), 30%, 70%)')
    --secondary-color s('hsl(var(--secondary-hue), 30%, 70%)')
    --link-color #6bf
    --word-spacing 0.05em
    --image-opacity 0.7

*
  box-sizing border-box
  margin 0
  padding 0
  for attr in background color opacity text-decoration-color text-underline-offset
    transition attr var(--transition)

body
  align-items stretch
  background var(--background)
  color var(--foreground)
  display flex
  flex-flow column nowrap
  font-family 'Alegreya', serif
  // https://websemantics.uk/tools/responsive-font-calculator/
  font-size clamp(0.9em, calc(0.9em + ((1vw - 0.2em) * 0.75)), 1.5em)
  font-synthesis none
  font-variant-numeric oldstyle-nums proportional-nums slashed-zero // lining-nums tabular-nums stacked-fractions
  line-height 1.5
  text-rendering geometricPrecision

main
  padding 1em
  min-width 20ch
  max-width 80ch

blockquote, dl, ol, p, pre, ul
  hyphens auto
  margin-bottom 0.75em
  margin-top 0.75em
  overflow-wrap break-word
  word-spacing var(--word-spacing)

ol, ul
  padding-left 1.5em

h1, h2, h3, h4
  font-family 'Alegreya Sans', serif
  font-weight 700
  hyphens auto
  overflow-wrap break-word

h1
  font-size 1em * $font-ratio * $font-ratio * $font-ratio
  line-height 1.2

h2
  font-size 1em * $font-ratio * $font-ratio
  line-height 1.3

h3
  font-size 1em * $font-ratio
  line-height 1.4

a
  color var(--link-color)
  text-underline-offset 0.1em

  &:hover
    text-underline-offset 0.2em

img
  max-width 100%
  opacity var(--image-opacity)
  &:hover
    opacity 1

code, kbd, pre[class*='language-']
  background var(--console-background)
  color var(--console-foreground)
  font-family 'Inconsolata', monospace

pre[class*='language-']
  border-radius 0.75em
  font-size 0.9em
  margin 0.75em -0.75em
  padding 0.75em

code:not([class*='language-'])
  border-radius 0.25em
  padding 0.25em 0.5em

header, footer
  color var(--background)
  font-family 'Alegreya Sans', sans-serif

  ol, ul
    margin 0
    padding 0

header
  background var(--primary-color)
  display flex
  flex-flow row nowrap

  #theme-toggle
    background transparent
    border none
    cursor pointer
    flex 0 0 auto
    padding 0.5em

    svg
      fill none
      height 1.5em
      margin-right 1em
      stroke var(--background)
      stroke-width 1.5
      stroke-linecap round
      stroke-linejoin round
      width 1.5em

  nav
    flex 1 1 auto
    height 3em
    line-height 3

    ul
      display flex
      list-style none
      li
        margin-left 1em
        a
          color inherit
          font-weight 700
          text-decoration none

footer
  background var(--secondary-color)
  padding 0.5em 0.7em

  img
    height 1em
    margin-left 0.2em
    vertical-align text-bottom

html.dark-theme #sun
html:not(.dark-theme) #moon
  display none

.permalink-symbol
  text-decoration none

  &[aria-hidden="true"]
    opacity 0
    visibility hidden

*:hover > .permalink-symbol
*:target > .permalink-symbol
  opacity 1
  visibility visible

.tag
  background var(--tag-background)
  border-radius 0.2em
  color var(--foreground)
  display inline-block
  margin 0 0.5em 0.5em 0
  padding 0.2em 0.5em
  text-decoration none

ul.tags
  display flex
  flex-flow row wrap
  list-style none
  padding 0

  li
    flex 0 0 auto

  .count
    color var(--faint-foreground)
    margin 0 0.7em 0 -0.2em

    &:before
      content '×'
      font-size 0.7em

ul.posts
  list-style none
  padding 0

  span.date
    display inline-block
    text-align right
    padding-right 1em

  .yyyy
    width 4em
  .mm
    width 2.5em
  .dd
    width 2.5em

.elemlabs
  color #f60
  font-variant small-caps
  letter-spacing 0.2em

blockquote.sms
  background #bdf
  color #333
  border-radius 0.75em
  font-family 'Roboto', sans-serif
  margin 2em
  max-width 30ch
  min-width 20ch
  padding 0.1em 1em

#landing
  height 100vh
  width 100vw
  display flex
  align-items center
  justify-content space-evenly
  font-size 6em
  text-shadow #999 0 0 0.1em

  a
    text-decoration none

#heroes
  display grid
  grid-template-columns repeat(auto-fit, minmax(40ch, 1fr))

  div
    background-blend-mode screen
    background-color var(--background)
    background-image var(--bg-image)
    background-position center
    background-size cover
    display grid
    font-size calc(2em + 5vw)
    height 2em
    line-height 1
    place-content center

    a
      color #222
      text-decoration none
      text-align center

    &:hover
      background-color #000
      a
        color #fff
        text-shadow 0px 0px 10px #000

.post
  .date
    font-size 1.1em
    line-height 1

.MathJax
  background var(--faint-background)
  border-radius 0.2em
  padding 0.2em 0.5em
